import ConfirmationModal from "../../../../components/ConfirmationModal/ConfirmationModal";
import Documents from "../../../../components/Documents/Documents";
import RenameNode from "../../../../components/RenameNode/RenameNode";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { DELETE } from "../../../../service/api";
import { updateCustomerDocPage } from "../../../../store/features/CustomerDetails.slice";
import {
  setConfirmFolderDelete,
  setOpenDeleteFolderModalSt,
  setReloadTable,
} from "../../../../store/features/FolderTree";
import { ApiUrl } from "../../../../utils/constants/ApiUrl";
import { STATUS } from "../../../../utils/constants/common";
import { CONFIRM_MODAL_MESSAGES } from "../../../../utils/constants/messages";

const CustomerSpecificFiles = () => {
  const {
    customer_specific_files,
    doc_current_page,
    total_docs,
    selectedRecord,
  } = useAppSelector((state) => state.CustomerDetailsReducer);
  const { openDeleteModal, openRenameFolderModal, isFolder } = useAppSelector(
    (state) => state.FolderTreeReducer
  );
  const dispatch = useAppDispatch();
  //function on changing page
  const changePage = (pageNumber: number) => {
    dispatch(updateCustomerDocPage(pageNumber));
  };

  const title = isFolder
    ? CONFIRM_MODAL_MESSAGES.DELETE_FOLDER.title
    : CONFIRM_MODAL_MESSAGES.DELETE_FILE.title;
  const description = isFolder
    ? CONFIRM_MODAL_MESSAGES.DELETE_FOLDER.description
    : CONFIRM_MODAL_MESSAGES.DELETE_FILE.description;

  const confirmAction = () => {
    if (!isFolder) {
      _handleFileDelete();
      return;
    }
    dispatch(setConfirmFolderDelete(true));
  };

  const _handleFileDelete = () => {
    dispatch(
      DELETE("file/delete", `${ApiUrl.deleteFile}${selectedRecord?.id}/`)()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        dispatch(setOpenDeleteFolderModalSt({ open: false, isFolder: true }));
        dispatch(setReloadTable(true));
      }
    });
  };

  return (
    <>
      <Documents
        isCustomerSpecific={true}
        docTitle="Uploads"
        data={customer_specific_files}
        tableParams={{ page: doc_current_page, total_data: total_docs }}
        onDocPageChange={changePage}
      />
      <ConfirmationModal
        open={openDeleteModal}
        okText="Yes, Delete"
        title={title}
        subText={description}
        customClass="error-btn"
        onCancel={() =>
          dispatch(setOpenDeleteFolderModalSt({ open: false, isFolder: true }))
        }
        onOk={confirmAction}
      />
      <RenameNode
        open={openRenameFolderModal}
        type={isFolder ? "Folder" : "File"}
      />
    </>
  );
};

export default CustomerSpecificFiles;
