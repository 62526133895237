import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { INodes } from "../../../interface/ProductDetails";
import { DELETE, GET, PATCH } from "../../../service/api";
import {
  fetchNodeDetails,
  setActiveNode,
  setBreadCrumbNodes,
  setConfirmFolderDelete,
  setConfirmRenameFolder,
  setCustomerActiveNode,
  setNodes,
  setOpenDeleteFolderModalSt,
  setOpenRenameFolderModal,
  updateDocPage,
} from "../../../store/features/FolderTree";
import { ApiUrl } from "../../../utils/constants/ApiUrl";
import { FOLDER_ACTIONS, STATUS } from "../../../utils/constants/common";
import {
  capitalizeFirstLetter,
  isAdmin,
  isCurrentUser,
  isIndividualUser,
  isSuperAdminUser,
  triggerToastMessage,
} from "../../../utils/utils";
import { RightArrow, FolderIcon } from "../../Icons/Icons";
import { TreeLoader } from "../../Loader/Loader";
import ActionMenu from "../../ActionMenu/ActionMenu";
import { updateCustomerDocPage } from "../../../store/features/CustomerDetails.slice";
import { Tooltip } from "antd";
import { FILE_STARTED_DOWNLOAD } from "../../../utils/constants/messages";

export const TreeNode = ({
  data,
  id,
  name,
  isExpanded,
  isLoading = false,
  children = [],
  type = "",
  hasPermission = false,
}: {
  data: INodes;
  id: string;
  name: string;
  isExpanded: boolean;
  isLoading?: boolean;
  children?: INodes[];
  type?: string;
  hasPermission?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    active_node,
    nodes,
    fetchNode,
    customer_active_node,
    confirmFolderDelete,
    confirmRenameFolder,
    renamedFolder,
    breadcrumbNodes,
  } = useAppSelector((state) => state.FolderTreeReducer);
  const { selectedRecord } = useAppSelector(
    (state) => state.CustomerDetailsReducer
  );
  const isSuperAdmin = isSuperAdminUser();
  const userHasPermission =
    (isAdmin() || isIndividualUser()) && isCurrentUser(data?.created_by || "");

  const hasSubNodes =
    data?.hasFiles ||
    (data?.sub_nodes && data?.sub_nodes?.length > 0) ||
    (data?.no_of_documents && data?.no_of_documents > 0) ||
    (data?.no_of_sub_nodes && data?.no_of_sub_nodes > 0);

  useEffect(() => {
    //to auto expand and show newly added folders in tree
    if (fetchNode && data?.id === active_node?.id) {
      expandOrCollapseNode(active_node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNode, active_node?.id]);

  useEffect(() => {
    confirmRenameFolder &&
      renamedFolder?.node &&
      data?.id === selectedRecord?.id &&
      _renameFolder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmRenameFolder, selectedRecord?.id, renamedFolder?.node]);

  useEffect(() => {
    confirmFolderDelete && data?.id === selectedRecord?.id && _deleteFolder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmFolderDelete, selectedRecord?.id]);

  const expandOrCollapseNode = (treeNode: INodes) => {
    !type && _createSelectedNodeParams(treeNode);
  };

  const selectNode = (selectedNode: INodes) => {
    if (
      active_node?.id === selectedNode?.id ||
      customer_active_node?.id === selectedNode?.id
    ) {
      return;
    }
    !type
      ? dispatch(setActiveNode(selectedNode))
      : dispatch(setCustomerActiveNode(selectedNode));
    if (!selectedNode?.path && !type) _fetchChildNodes(selectedNode, true);
    _fetchDocs(type, selectedNode);
    _createBreadcrumbOptions(selectedNode);
  };

  const _fetchDocs = (type: string, selectedNode: INodes) => {
    // fetching pragma node documents
    if (!type) {
      dispatch(
        GET(
          "nodes/files",
          `${ApiUrl.productList}${selectedNode?.id}/documents/?page_size=10&page=1`
        )() // fetching the call again on page change
      );
      dispatch(updateDocPage(1));
      return;
    }
    dispatch(
      GET(
        "customerSpecific/files",
        `${ApiUrl.customerSpecificNodes}${selectedNode?.id}/documents/?page_size=10&page=1`
      )()
    );
    dispatch(updateCustomerDocPage(1));
  };

  const _createSelectedNodeParams = (treeNode: INodes) => {
    treeNode = {
      ...treeNode,
      expanded:
        (treeNode?.sub_nodes || [])?.length > 0 ||
        (treeNode?.no_of_sub_nodes && treeNode?.no_of_sub_nodes > 0)
          ? !treeNode.expanded
          : treeNode?.expanded,
    };
    // if clikced on the root node --> set the nodes as the selected node

    if (treeNode?.is_root_node) {
      !fetchNode ? dispatch(setNodes(treeNode)) : _fetchChildNodes(treeNode);
      return;
    }
    //If the node is clicked for a first time or a new folder is added, fetch the results from API
    if (
      !Object.hasOwn(treeNode, "sub_nodes") ||
      fetchNode ||
      (treeNode?.sub_nodes?.length === 0 &&
        treeNode?.no_of_sub_nodes &&
        treeNode?.no_of_sub_nodes > 0)
    ) {
      _fetchChildNodes(treeNode);
      return;
    }
    //If node is clicked before and has sub nodes, update the expand icon status
    const updatedTree = _updateTreeData(
      nodes?.sub_nodes || [],
      treeNode,
      treeNode?.sub_nodes || []
    );
    dispatch(setNodes({ ...nodes, sub_nodes: [...updatedTree] }));
  };

  const _fetchChildNodes = (treeNode: INodes, nodeSelected = false) => {
    fetchNode && dispatch(fetchNodeDetails(false));
    treeNode = { ...treeNode, loading: !nodeSelected };
    dispatch(
      GET("node/details", `${ApiUrl.productList}${treeNode.id}/`)()
    ).then((res) => {
      treeNode = { ...treeNode, loading: false };
      const {
        payload: { data, status },
      } = res;
      if (status === STATUS.SUCCESS) {
        treeNode = {
          ...treeNode,
          path: data?.path,
          sub_nodes: [...data?.sub_nodes],
          expanded: !nodeSelected && data?.sub_nodes?.length > 0,
          hasFiles: fetchNode || treeNode?.hasFiles,
        };
        _checkUserActionPermission(treeNode); //checking for file action permission
        if (treeNode?.is_root_node) {
          dispatch(
            setNodes({
              ...nodes,
              sub_nodes: [...data?.sub_nodes],
            })
          );
          return;
        }
        const updatedTree = _updateTreeData(
          nodes?.sub_nodes || [],
          treeNode,
          data?.sub_nodes
        );
        dispatch(setNodes({ ...nodes, sub_nodes: [...updatedTree] }));
        if (nodeSelected) _createBreadcrumbOptions(treeNode);
      }
    });
  };

  const _checkUserActionPermission = (treeNode: INodes) => {
    if (
      (userHasPermission || treeNode?.hasPermission) &&
      treeNode?.sub_nodes?.length
    ) {
      treeNode.sub_nodes = treeNode?.sub_nodes?.map((childNodes: INodes) => ({
        ...childNodes,
        hasPermission: true,
      }));
    }
  };

  const _updateTreeData = (
    productNodes: INodes[],
    treeNode: INodes,
    childData: INodes[]
  ): INodes[] =>
    productNodes.map((node: INodes) => {
      if (node.id === treeNode?.id) {
        return {
          ...treeNode,
          sub_nodes: treeNode?.sub_nodes,
        };
      }
      if (node?.sub_nodes) {
        return {
          ...node,
          sub_nodes: _updateTreeData(node?.sub_nodes, treeNode, childData),
        };
      }
      return node;
    });

  const _createBreadcrumbOptions = (selectedNode: INodes) => {
    if (selectedNode?.path && !Array.isArray(selectedNode?.path)) {
      const pathArr: { name: string; created_by: string }[] = Object.values(
        selectedNode?.path
      );
      const parentIdArr = Object.keys(selectedNode?.path);
      const breadCrumbNodes = parentIdArr.map((id: string, index) => ({
        id,
        name: pathArr[index]?.name,
      }));
      breadCrumbNodes.push({ id: selectedNode?.id, name: selectedNode?.name });
      dispatch(setBreadCrumbNodes([...breadCrumbNodes]));
    }
    if (selectedNode?.is_root_node)
      dispatch(
        setBreadCrumbNodes([{ id: selectedNode?.id, name: selectedNode?.name }])
      );
  };

  const handleMenuClick = (item: { value: string }) => {
    switch (item.value) {
      case FOLDER_ACTIONS.DELETE:
        dispatch(setOpenDeleteFolderModalSt({ open: true, isFolder: true }));
        break;
      case FOLDER_ACTIONS.RENAME:
        dispatch(setOpenRenameFolderModal({ open: true, isFolder: true }));
        break;
      case FOLDER_ACTIONS.DOWNLOAD:
        triggerToastMessage(
          FILE_STARTED_DOWNLOAD.message,
          "info",
          FILE_STARTED_DOWNLOAD.description
        );
        break;
      default:
        break;
    }
  };

  const _deleteFolder = () => {
    dispatch(setConfirmFolderDelete(false));
    dispatch(
      DELETE("nodes/delete", `${ApiUrl.productList}${selectedRecord?.id}/`)()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        const updatedTree = _updateTreeData(
          nodes?.sub_nodes || [],
          { ...selectedRecord, isDeleted: true },
          []
        );
        dispatch(setNodes({ ...nodes, sub_nodes: [...updatedTree] }));
        active_node?.id === selectedRecord?.id && _resetNodeSelection();
        triggerToastMessage(
          "Folder deleted",
          undefined,
          `You deleted '${name}'`
        );

        dispatch(setOpenDeleteFolderModalSt({ open: false, isFolder: true }));
      }
    });
  };

  const _renameFolder = () => {
    dispatch(setConfirmRenameFolder(false));
    dispatch(
      PATCH(
        "nodes/rename",
        `${ApiUrl.productList}${selectedRecord?.id}/${ApiUrl.renameFolder}`,
        {
          ...renamedFolder,
        }
      )()
    ).then((res: any) => {
      if (res?.payload?.status === STATUS.SUCCESS) {
        if (active_node?.id === selectedRecord?.id)
          dispatch(
            setActiveNode({ ...active_node, name: renamedFolder?.new_name })
          );
        const updatedTree = _updateTreeData(
          nodes?.sub_nodes || [],
          { ...selectedRecord, name: renamedFolder?.new_name },
          selectedRecord?.sub_nodes || []
        );
        dispatch(setNodes({ ...nodes, sub_nodes: [...updatedTree] }));
        _renameBreadcrumbs();
        dispatch(setOpenRenameFolderModal({ open: false, isFolder: true }));
        triggerToastMessage(
          "Folder renamed",
          "success",
          `You renamed folder '${name}' to '${renamedFolder?.new_name}'`
        );
      }
    });
  };

  // renaming the breadcrumbs after renaming a folder
  const _renameBreadcrumbs = () => {
    const breadcrumbIndex = breadcrumbNodes.findIndex(
      (breadcrumb: any) => breadcrumb.id === selectedRecord?.id
    );
    if (breadcrumbIndex < 0) return;
    const breadcrumbs = [...breadcrumbNodes];
    breadcrumbs[breadcrumbIndex] = {
      id: selectedRecord?.id,
      name: renamedFolder?.new_name,
    };
    dispatch(setBreadCrumbNodes([...breadcrumbs]));
  };

  const _resetNodeSelection = () => {
    dispatch(setActiveNode(nodes));
    _fetchDocs(type, nodes);
    _createBreadcrumbOptions(nodes);
  };

  const showArrow =
    (data?.sub_nodes && data?.sub_nodes?.length > 0) ||
    (data?.no_of_sub_nodes && data?.no_of_sub_nodes > 0);

  return (
    <>
      <li className="d-flex tree">
        <Tooltip
          placement="top"
          title={
            showArrow && !data?.is_root_node
              ? isExpanded
                ? "Collapse"
                : "Expand"
              : ""
          }
        >
          <span
            className={`arrow ${isExpanded && "arrow-down"} ${
              showArrow && "arrow-hover"
            }`}
            onClick={(e) => expandOrCollapseNode(data)}
          >
            {showArrow ? (
              <>{!isLoading ? <RightArrow /> : <TreeLoader />}</>
            ) : (
              <></>
            )}
          </span>
        </Tooltip>
        <span className="d-flex folder" onClick={() => selectNode(data)}>
          <FolderIcon />
          <span
            className={`name ${
              (active_node?.id === id || customer_active_node?.id === id) &&
              "active"
            } ${hasSubNodes && "text-bold"}`}
          >
            {capitalizeFirstLetter(name)}
          </span>
          {!data?.is_root_node &&
            !type &&
            (isSuperAdmin || userHasPermission || hasPermission) && (
              <span>
                <ActionMenu
                  data={data}
                  handleAction={(item: any) => handleMenuClick(item)}
                  type="download"
                />
              </span>
            )}
        </span>
      </li>
    </>
  );
};
