import { Cascader, CascaderProps, Dropdown, MenuProps } from "antd";
import { RightArrowDark, VerticalDots } from "../Icons/Icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { setSelectedRecord } from "../../store/features/CustomerDetails.slice";
import { useState } from "react";
import { Option, options } from "../TreeView/TreeData";

const ActionMenu = ({
  items,
  handleAction,
  data = {},
  type = "",
}: {
  items?: MenuProps["items"];
  handleAction: Function;
  data?: any;
  type?: string;
}) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState([]);

  const handleMenuClick: MenuProps["onClick"] = (item: MenuInfo) => {
    if (type) return;
    handleAction(item);
    item.domEvent.stopPropagation();
    item.domEvent.preventDefault();
  };

  const selectRow = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedRecord(data));
  };

  const onChange: CascaderProps<Option>["onChange"] = (_, selectedOptions) => {
    handleAction(selectedOptions[0]);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const moreAction = (
    <span className="space" onClick={selectRow}>
      <VerticalDots />
    </span>
  );

  return (
    <>
      {!type ? (
        <Dropdown
          className="more"
          trigger={["click"]}
          menu={{ items, onClick: (e) => handleMenuClick(e) }}
        >
          {moreAction}
        </Dropdown>
      ) : (
        <div onClick={handleClick}>
          <Cascader
            value={value}
            onDropdownVisibleChange={() => setValue([])}
            options={options}
            onChange={onChange}
            expandIcon={<RightArrowDark />}
            expandTrigger="hover"
            popupClassName="custom-cascader"
          >
            {moreAction}
          </Cascader>
        </div>
      )}
    </>
  );
};

export default ActionMenu;
