import { Form, Input, InputRef, Modal } from "antd";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { VALIDATION_ERRORS } from "../../utils/constants/common";
import { spaceRegex } from "../../utils/validators";
import { useAppSelector } from "../../hooks/reduxHooks";

const AddCustomerModal = ({
  open,
  add,
  reset,
  title,
  placeholder,
  isSuccess,
  type = "",
  data = { name: "" },
  maxlength = 255,
}: {
  open: boolean;
  add: Function;
  reset: Function;
  title: string;
  placeholder: string;
  isSuccess: boolean;
  type?: string;
  data?: { name: string };
  maxlength?: number;
}) => {
  const [addForm] = Form.useForm();
  const [btnStatus, setBtnStatus] = useState(true);
  const inputRef = useRef<InputRef>(null);
  const { isFolder } = useAppSelector((state) => state.FolderTreeReducer);

  useEffect(() => {
    addForm.setFieldValue("name", data?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.name]);

  useEffect(() => {
    if (isSuccess) cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const triggerValidationOnBlur = (field: string) => {
    const val = addForm.getFieldValue(field);
    addForm.setFieldsValue({ [field]: val?.trim() });
    addForm.validateFields([field]);
  };

  const handleFormChange = () => {
    const hasErrors =
      !addForm.isFieldsTouched(["name"], true) ||
      addForm.getFieldsError().some(({ errors }) => errors.length);
    setBtnStatus(hasErrors);
  };

  const addNew = () => {
    add({ ...addForm.getFieldsValue() });
  };

  const cancel = () => {
    reset();
    addForm.resetFields();
    setBtnStatus(true);
  };

  const isNameEmpty = (e: ChangeEvent<HTMLInputElement>) => {
    if (spaceRegex.test(e.target.value)) {
      e.target.value = "";
    }
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    addForm.setFieldValue("name", value);
  };

  const getEditableName = () => {
    const name = addForm.getFieldValue("name");
    const lastDotIndex = name?.lastIndexOf(".");
    return lastDotIndex !== -1 ? name?.slice(0, lastDotIndex) : name;
  };

  const getFileExtension = () => {
    const name = addForm.getFieldValue("name");
    const lastDotIndex = name?.lastIndexOf(".");
    return lastDotIndex !== -1 ? name?.slice(lastDotIndex) : "";
  };

  return (
    <>
      <Modal
        afterOpenChange={() => {
          isFolder && inputRef.current?.focus({ cursor: "all" });
        }}
        destroyOnClose
        className="modal-title"
        title={title}
        open={open}
        okText={!type ? "Add" : "Update"}
        okButtonProps={{ size: "large", disabled: btnStatus }}
        cancelButtonProps={{ size: "large", type: "text" }}
        onOk={addNew}
        onCancel={cancel}
        closable={false}
        onClose={cancel}
      >
        <Form
          onFieldsChange={handleFormChange}
          autoComplete="off"
          size="large"
          form={addForm}
          layout="vertical"
          name="addForm"
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: VALIDATION_ERRORS.name,
                validateTrigger: "onBlur",
              },
              {
                min: 3,
                message: "Name should be atleast 3 characters",
                validateTrigger: "onBlur",
              },
              {
                max: maxlength,
                message: `Name cannot exceed ${maxlength} characters`,
                validateTrigger: "onBlur",
              },
            ]}
          >
            <Input
              autoFocus={true}
              ref={inputRef}
              value={`${getEditableName()}${getFileExtension()}`}
              onChange={handleInputChange}
              onInput={isNameEmpty}
              autoComplete="new-name"
              placeholder={placeholder}
              onBlur={() => triggerValidationOnBlur("name")}
              minLength={3}
              max={maxlength}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
